import React from "react"
import { useStaticQuery, graphql } from "gatsby"

const MainSection = () => {
  const data = useStaticQuery(graphql`
    query {
      serviceParking: file(
        relativePath: { eq: "products/service-parking.svg" }
      ) {
        publicURL
      }
    }
  `)

  return (
    <section className="section section-main">
      <div className="container">
        <h1 className="title has-text-white">
          <img
            className="icon"
            src={data.serviceParking.publicURL}
            alt="Estacionamento Rotativo"
          />
          <span>Estacionamento Rotativo</span>
        </h1>
      </div>
    </section>
  )
}

export default MainSection
